import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { Routes as AppRouter } from "./routes/index";
import '../../styles/App.scss';

import "@fontsource/open-sans";

const queryClient = new QueryClient();

export const App: React.FC = (): JSX.Element => {
    return (
        <QueryClientProvider client={queryClient}>
            <AppRouter />
            <ReactQueryDevtools />
        </QueryClientProvider>
    );
};

import React from "react";
import each from "lodash/each";
import get from "lodash/get";
import { Row, Col } from "react-bootstrap";
import "../../styles/components/Results.scss";
import Button from "../app/common/Button";
import { exportDataToExcel } from "../../utils/excelExport";

interface ResultProps {
    title?: String;
    summary?: String;
    examples: Array<String>;
    type?: String;
    comparison?: string;
    evidenceTable: any;
}

const worksheetColumns = [
    { header: "Authors", key: "authors" },
    { header: "Published Year", key: "published_year" },
    { header: "Title", key: "title" },
    { header: "Journal", key: "journal" },
    { header: "Volume", key: "volume" },
    { header: "Issue", key: "issue" },
    { header: "Pages", key: "pages" },
    { header: "Abstract", key: "abstract" },
    { header: "DOI", key: "doi" },
    { header: "Covidence #", key: "article_number" },
];

export const Result: React.FC<ResultProps> = ({
    summary,
    examples,
    type,
    comparison,
    evidenceTable,
}): JSX.Element => {
    const typeColor = (type: String | undefined) => {
        if (type && type.split(" ").length >= 2) {
            let strengthType = type.split(" ")[1].toLowerCase();
            let color;
            switch (strengthType) {
                case "strong":
                    color = "green";
                    break;
                case "moderate":
                    color = "brown";
                    break;
                case "weak":
                    color = "lightBlue";
                    break;
                case "insufficient":
                    color = "grey";
                    break;
                default:
                    color = "";
            }
            return color;
        } else {
            return "";
        }
    };
    return (
        <div className="result-container mt-3 mb-3">
            <Row>
                <div className="result-container__inner">
                    <div className={`${typeColor(type)} result-label`}>
                        {type}
                    </div>
                    <h5>Summary:</h5>
                    <p>{summary}</p>
                    <Row className="pt-3 results-row">
                        {examples.map((example, index) => {
                            return (
                                <Col md={4} className="d-flex" key={index}>
                                    <div className="d-flex">
                                        <div className="example-content-wrapper">
                                            <span>Example {index + 1}:</span>
                                            <p>{example}</p>
                                        </div>
                                    </div>
                                    {index === 0 && (
                                        <div className="example-comparison-container">
                                            <span className="example-comparison">
                                                {comparison &&
                                                comparison === "!="
                                                    ? "≠"
                                                    : comparison || "?"}
                                            </span>
                                        </div>
                                    )}
                                </Col>
                            );
                        })}
                    </Row>
                    {examples && examples.length > 1 && (
                        <>
                            <div className="supporting-evidence-container">
                                <Button
                                    className="supporting-evidence-button"
                                    title="Download Supporting Evidence"
                                    onClick={() => {
                                        const {
                                            evidence_table_studies:
                                                evidenceTableStudies,
                                        } = evidenceTable;
                                        let data: any[] = [];

                                        each(
                                            evidenceTableStudies,
                                            (evidenceTableStudy) => {
                                                const supportingEvidences = get(
                                                    evidenceTableStudy,
                                                    "study.supporting_evidences",
                                                    null
                                                );

                                                data = [
                                                    ...data,
                                                    ...supportingEvidences,
                                                ];
                                            }
                                        );

                                        exportDataToExcel(
                                            worksheetColumns,
                                            data,
                                            "Supporting Evidence"
                                        );
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Row>
        </div>
    );
};

import { Switch, BrowserRouter } from "react-router-dom";
import AppRoute from "./Route";
import { Home } from "../pages/Home";
import { Guidance } from "../pages/Guidance";
import { UnderConstructionPage } from "../pages/UnderConstructionPage";

export enum AppRoutes {
    About = "about",
    Research = "research",
    Resources = "resources",
    Team = "team",
    Tutorial = "tutorial",
    Guidance = "guidance",
    Results = "results",
}

export const Routes: React.FC = (): JSX.Element => {
    const { search } = window.location;
    
    return (
        <BrowserRouter>
            <Switch>
                <AppRoute
                    path="/"
                    exact
                    component={() => <Home search={search} />}
                />
                <AppRoute
                    path={`/${AppRoutes.About}`}
                    exact
                    component={UnderConstructionPage}
                />
                <AppRoute
                    path={`/${AppRoutes.Research}`}
                    exact
                    component={UnderConstructionPage}
                />
                <AppRoute
                    path={`/${AppRoutes.Resources}`}
                    exact
                    component={UnderConstructionPage}
                />
                <AppRoute
                    path={`/${AppRoutes.Team}`}
                    exact
                    component={UnderConstructionPage}
                />
                <AppRoute
                    path={`/${AppRoutes.Tutorial}`}
                    exact
                    component={UnderConstructionPage}
                />
                <AppRoute
                    path={`/${AppRoutes.Guidance}`}
                    exact
                    component={Guidance}
                />

                {/* redirect user to Home page if route does not exist */}
                <AppRoute component={() => <Home search={search} />} />
            </Switch>
        </BrowserRouter>
    );
};

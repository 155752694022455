import { Modal, Button } from "react-bootstrap";

export const DisclaimerModal: React.FC<{ show: boolean; onClose: () => void }> =
    ({ show, onClose }): JSX.Element => {
        return (
            <Modal show={show} onHide={onClose}>
                <Modal.Header>
                    <Modal.Title>What this tool does not cover</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        These are important questions, but our tool does not yet
                        have the information needed to make these types of
                        recommendations:
                        <ul>
                            <li>
                                Recommendations for getting readers to “like”
                                how you present numbers, wanting to engage with
                                what you are presenting
                            </li>
                            <li>
                                {" "}
                                Recommendations for getting readers to trust how
                                you present numbers{" "}
                            </li>
                            <li>
                                Recommendations for helping readers determine
                                the best option (when the best option is not the
                                same for everyone){" "}
                            </li>
                            <li>
                                {" "}
                                Recommendations for helping readers do
                                calculations{" "}
                            </li>
                            <li>
                                {" "}
                                we recommend you do calculations for the readers
                                whenever possible{" "}
                            </li>
                            <li>
                                Recommendations for getting readers recognize
                                when numbers are approximately equal (or not)
                            </li>
                        </ul>
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

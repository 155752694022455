import { ISurvey, Page, Choice, ChoiceCondition } from "./Survey";
import { Accordion } from "react-bootstrap";

type ProgressContainerProps = {
    title: string;
    answers?: any;
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    survey: ISurvey;
    expandable?: boolean;
};

const getChoiceShortText = (page: Page, answers: any) => {
    const { pageName, questions, subQuestions } = page;

    const pageAnswers = answers[pageName || ""];

    if (!pageAnswers) {
        // This should never happen
        return "";
    }

    const pageAnswerKeys = Object.keys(pageAnswers);
    let shortLabel = "";

    if (pageAnswerKeys.length > 0) {
        const lastPageAnswer = pageAnswerKeys[pageAnswerKeys.length - 1];
        if (questions && questions.length > 0) {
            const subQuestionsList = subQuestions || [];

            [...questions, ...subQuestionsList].forEach((question) => {
                const { choices, choiceConditions } = question;
                const choicesToSearch = choiceConditions ? choiceConditions.reduce((results: Choice[], choiceCondition: ChoiceCondition) => {
                  const { choices } = choiceCondition;
                  results.push(...choices);

                  return results;
                }, []) : choices

                const choice = choicesToSearch?.find((choice) => {
                    return choice.id === pageAnswers[lastPageAnswer];
                });

                if (choice) {
                    shortLabel = choice.shortLabel ? choice.shortLabel : "";
                }
            });
        }
    }

    return shortLabel;
};

export const ProgressContainer: React.FC<ProgressContainerProps> = ({
    title,
    answers,
    index,
    setIndex,
    survey,
    expandable,
}): JSX.Element => {
    const { pages } = survey;

    return (
      <>
      <Accordion className="progress-accordion" defaultActiveKey={expandable ? "" : "0"}>
      <Accordion.Item eventKey="0">
        <div className="progress-cotainer">
          <Accordion.Header>
          <div className="title-container">
            <h5>{title}</h5>
          </div>
          </Accordion.Header>
          <Accordion.Body>
          { !!answers &&
            pages.filter(page => answers.hasOwnProperty(page.pageName)).map((page, index) => {
              return (
                <div className="selection" key={`${page.pageName}_${index}`}>
                  <div className="selection-title">{page.navigationTitle}</div>
                  <div className="selection-description">
                  {getChoiceShortText(page, answers)}
                  </div>
                  <button className="edit-selection d-sm-none d-md-block" onClick={() => setIndex(index)}>
                  (edit)
                  </button>
                </div>
              );
            })
          }
          </Accordion.Body>
        </div>
        </Accordion.Item>
        </Accordion>
      </>
    );
};

import { Container } from "react-bootstrap";
import Button from '../common/Button';
import AccordionComponent from '../common/AccordionComponent';

import '../../../styles/components/Home.scss';
import '../../../styles/components/Accordion.scss';
import { AppRoutes } from '../routes';

export const Home: React.FC<{ search: string }> = ({ search }): JSX.Element => {
    return (
        <div className="home-container">
            <Container>
                <div className="page-header">
                    <div>
                        <p>
                          Do you need help communicating health-related numbers?<br/>
                          Do you know how to help people figure out whether a risk is high or low?<br/>
                          Do you know which number formats people understand well?<br/>
                          This tool can help.<br/>
                        </p>
                          <br/>
                          <h3>What this tool covers:</h3>
                          <p>We give advice to people who want to communicate health-related numbers to patients and the public.</p>
                          <p>We will first ask you a series of questions to better understand the <b>GOALS</b> of your message and the <b>NUMBERS</b> you want to communicate.</p>
                          <p>We will focus on the most common communication goals by helping you to consider whether it is most important for your audience to do things like take an action, see a number as larger or smaller, classify a number as having a particular meaning (e.g., high risk), or remember the specific number.</p>
                          <p>We will then present you with evidence-based <b>RECOMMENDATIONS</b> for communicating the <b>NUMBERS</b> you want to communicate based on the <b>GOALS</b> you choose. </p>
                          <p>The research evidence is clear that different methods for communicating numbers are better for achieving different goals. No one method is “best” for all situations. Thus, it is important that you consider what your <b>most important</b> goal is for your communication.</p>
                          <div className="begin-guidance-actions-section">
                            <Button
                              pathName={`${AppRoutes.Guidance}${search}`}
                              title="Begin"
                            ></Button>
                          </div>
                        <AccordionComponent
                          title='What this tool does NOT cover'
                          eventKey='0'
                          children={
                            <div>
                              <p>These are important questions, but our tool does not yet have the information needed to make these types of recommendations:</p>
                              <ul>
                                <li>Recommendations for getting readers to <span>“like”</span> how you present numbers, wanting to engage with what you are presenting</li>
                                <li>Recommendations for getting readers to <span>trust</span> how you present numbers</li>
                                <li>Recommendations for helping readers <span>determine the best option</span> (when the best option is not the same for everyone)</li>
                                <li>Recommendations for helping readers do <span>calculations</span> – we recommend you do calculations for the readers whenever possible</li>
                                <li>Recommendations for getting readers recognize when numbers are <span>approximately equal</span> (or not)</li>
                              </ul>
                            </div>}
                          />
                        <AccordionComponent
                          title='Additional considerations'
                          eventKey='1'
                          className='secondary'
                          children={
                            <div>
                              <p>Please also note that certain other features may make it harder for your audience to understand your message</p>
                              <ul>
                                <li>More complex data – It is harder to communicate complex data. For example, communicating information about multiple groups at the same time is more difficult than talking about a single group. Also, discussing multiple variables (such as the risks of several different cancers, or several side effects) at one time rather than a single variable can make the information harder to grasp.</li><br />
                                <li>Low numeracy – Audiences with low numeracy are likely to have more difficulty understanding numbers. Even more people have low numeracy than have low literacy, so it is a good idea to assume that all audiences have limited numeracy. Our recommendations assume a low to moderate level of numeracy, but they do not change for other groups with higher numeracy.</li><br />
                                <li>Extreme values – It is particularly challenging to communicate information about extreme values, such as very rare events and very small or very large numbers. As a result, our recommendations may not apply as well to messages that contain extreme values.</li><br />
                              </ul>
                            </div>}
                          />
                    </div>
                </div>
            </Container>
        </div>
    );
};

import { Survey } from "../../Survey/Survey";
import * as Survey2 from "survey-react";

export const Guidance: React.FC<{ search: string }> = ({
    search,
}): JSX.Element => {
    new Survey2.Model({});

    return (
        <>
            <Survey search={search} />
        </>
    );
};

import { ISurvey } from "./Survey";
import { isMobile } from 'react-device-detect';

interface IProgressBar {
    survey: ISurvey;
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const ProgressBar: React.FC<IProgressBar> = ({
    survey,
    index,
    setIndex
}): JSX.Element => {
    const { pages } = survey;

    const updateProgress = (newPageIndex: number) => {
        const lastIndex = pages.length - 1;

        if (lastIndex !== newPageIndex && newPageIndex < index) {
            setIndex(newPageIndex);
        }
    }

    return (
        <>
            <div className="sv_progress-buttons__container-center">
                <div className="sv_progress-buttons-app__container">
                    <div className="sv_progress-buttons__list-container">
                    {isMobile ?
                      <ul className="sv_progress-buttons__list">
                        {pages.filter((page, pageIndex) => pageIndex === index).map(currPage => (
                          <li className="sv_progress-buttons__list-element--current mobile">
                            <div
                                className="sv_progress-buttons__page-title"
                                title={currPage.navigationTitle}
                            >
                                {currPage.navigationTitle}
                            </div>
                          </li>
                        ))}
                      </ul>
                      :
                        <ul className="sv_progress-buttons__list">
                            {pages.map((page, pageIndex) => {
                                const { navigationTitle } = page;

                                let liClass = "";

                                if (index === pageIndex) {
                                    liClass =
                                        "sv_progress-buttons__list-element--current";
                                } else if (index > pageIndex) {
                                    liClass =
                                        "sv_progress-buttons__list-element--previous";
                                }

                                return (
                                    <li className={liClass} onClick={() => updateProgress(pageIndex)} key={pageIndex}>
                                        <div
                                            className="sv_progress-buttons__page-title"
                                            title={navigationTitle}
                                        >
                                            {navigationTitle}
                                        </div>
                                        <div className="sv_progress-buttons__page-description"></div>
                                    </li>
                                );
                            })}
                        </ul>
                      }
                    </div>
                </div>
            </div>
        </>
    );
};

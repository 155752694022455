import React from 'react';
import { Accordion } from "react-bootstrap";

interface AccordionProps {
  title: string,
  eventKey: string,
  children: React.ReactNode,
  className?: string,
}

const AccordionComponent: React.FC<AccordionProps> = ({ title, eventKey, children, className }) => {
  return (
    <Accordion className={className ? `${className} expansion-panel` : "expansion-panel"}>
      <Accordion.Item eventKey={eventKey}>
        {className === 'secondary' ? <div className="divider-wrapper"><div className="divider"></div></div> : null}
        <Accordion.Header><span>{title}</span></Accordion.Header>
        <Accordion.Body>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
};

export default AccordionComponent;

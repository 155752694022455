import React, { memo } from "react";
import { Container } from "react-bootstrap";

import { NavHeader } from "../../common/NavHeader";
import { Footer } from "../../common/Footer";
import { ToastContainerWrapper } from "../../common/ToastContainerWrapper";

interface LayoutProps {
    children: React.ReactNode;
}

export const DefaultLayout: React.FC<LayoutProps> = memo(
    ({ children }: LayoutProps): JSX.Element => {
        return (
            <div id="page-container">
                <NavHeader />
                <div id="content-wrap">
                    <Container>{children}</Container>
                </div>
                <ToastContainerWrapper />
                <Footer />
            </div>
        );
    }
);

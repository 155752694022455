import * as Excel from "exceljs";
import { saveAs } from "file-saver";

export const exportDataToExcel = async (
    columns: { header: string; key: string }[],
    data: any[],
    fileName: string
) => {
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet(fileName);
    worksheet.columns = columns;
    
    // force the columns to be at least as long as their header row.
    // Have to take this approach because ExcelJS doesn't have an autofit property.
    worksheet.columns.forEach((column) => {
        const header = column.header ? column.header : [];

        column.width = header.length < 12 ? 12 : header.length;
    });

    // Make the header bold.
    // Note: in Excel the rows are 1 based, meaning the first row is 1 instead of 0.
    worksheet.getRow(1).font = { bold: true };

    // Dump all the data into Excel
    data.forEach((e) => {
        // By using destructuring we can easily dump all of the data into the row without doing much
        // We can add formulas pretty easily by providing the formula property.
        worksheet.addRow({
            ...e,
        });
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, fileName + fileExtension);
};

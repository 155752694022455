import React, { useState, createContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import queryString from "query-string";

import "../../styles/components/Survey.scss";
import { ProgressBar } from "./ProgressBar";
import { ProgressContainer } from "./ProgressContainer";
import { SectionQuestions } from "./SectionQuestions";
import { Results } from "../Results/Results";
import { survey } from "../../tests/fixtures/surveys";
import Button from "../app/common/Button";

export type Answers = {
    [key: string]: any;
};

export type Choice = {
    id: number;
    label: string;
    subQuestionId?: number;
    infoText?: string;
    showDisclaimer?: boolean;
    shortLabel?: string;
    description?: string;
    foreignReferenceCode?: string;
    warningLabel?: string;
};

export type ChoiceCondition = {
    sectionId: number;
    choiceId: number;
    choices: Choice[];
};

export type Question = {
    id: number;
    type: string;
    description?: string;
    name: string;
    isRequired: boolean;
    title: string;
    colCount: number;
    choiceConditions?: ChoiceCondition[];
    choices?: Choice[];
};

export type Page = {
    id?: number;
    navigationTitle: string;
    pageName?: string;
    questions?: Question[];
    subQuestions?: Question[];
    disclaimerText?: string;
};

export interface ISurvey {
    pages: Page[];
}

export const SurveyContext = createContext(survey);
export const IndexContext = createContext(0);
export const IndexDispatchContext = createContext<
    React.Dispatch<React.SetStateAction<number>>
>(() => {});
export const AnswersContext = createContext<Answers | undefined>({});

export const Survey: React.FC<{ search: string }> = ({
    search,
}): JSX.Element => {
    const params = queryString.parse(window.location.search);
    const [index, setIndex] = useState(0);
    const [answers, setAnswers] = useState<Answers>();
    const lastQuestionIndex = survey.pages.length - 2;
    const [submitted, setSubmitted] = useState(false);
    let currentPage = survey.pages[index];
    let disableSubmit = true;
    let resultsPageIndex = survey.pages.length - 1;

    if (
        index === lastQuestionIndex &&
        currentPage &&
        currentPage.pageName &&
        answers &&
        answers[currentPage.pageName]
    ) {
        disableSubmit = false;
    }

    const handleSetAnswer = (
        pageName: string,
        question: Question,
        value: number,
        answersToReset: string[]
    ) => {
        const previousPageAnswers =
            answers && answers[pageName] ? { ...answers[pageName] } : {};

        const newAnswers = {
            ...answers,
            [pageName]: { ...previousPageAnswers, [question.name]: value },
        };

        if (answersToReset && answersToReset.length > 0) {
            answersToReset.forEach((resetAnswerKey) => {
                delete newAnswers[pageName][resetAnswerKey];
            });
        }

        if (submitted && disableSubmit) {
            disableSubmit = false;
        }

        setAnswers(newAnswers);
    };

    const handleSubmit = () => {
        if (submitted) {
            setIndex(resultsPageIndex);
        } else {
            setIndex(index + 1);
            setSubmitted(true);
        }
    };

    return (
        <>
            <div className="sv_main">
                <ProgressBar
                    survey={survey}
                    index={index}
                    setIndex={setIndex}
                />
                {index === resultsPageIndex || (params.data_class_code && params.data_type_code && params.outcome_code) ? (
                    <SurveyContext.Provider value={survey}>
                        <IndexContext.Provider value={index}>
                            <IndexDispatchContext.Provider value={setIndex}>
                                <AnswersContext.Provider value={answers}>
                                    {params.results === "false" ? (
                                        <Container className="questionnaire-container">
                                            <Row>
                                                <Col
                                                    sm={12}
                                                    lg={8}
                                                    className="pt-4"
                                                >
                                                    <h3>
                                                        Thank you for filling
                                                        out the guidance
                                                        questionaire!
                                                    </h3>
                                                </Col>
                                                <Col lg={4}>
                                                    <ProgressContainer
                                                        title="Edit Selections"
                                                        survey={survey}
                                                        index={index}
                                                        setIndex={setIndex}
                                                        answers={answers}
                                                        expandable={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    ) : (
                                        <Results />
                                    )}
                                </AnswersContext.Provider>
                            </IndexDispatchContext.Provider>
                        </IndexContext.Provider>
                    </SurveyContext.Provider>
                ) : (
                    <Container className="questionnaire-container">
                        <Row>
                            <Col sm={12} lg={8}>
                                <SectionQuestions
                                    survey={survey}
                                    index={index}
                                    setIndex={setIndex}
                                    answers={answers}
                                    handleSetAnswer={handleSetAnswer}
                                />
                            </Col>
                            <Col lg={4}>
                                <ProgressContainer
                                    title="Edit Selections"
                                    survey={survey}
                                    index={index}
                                    setIndex={setIndex}
                                    answers={answers}
                                />
                                {(index === lastQuestionIndex || submitted) && (
                                    <Button
                                        title={
                                            submitted
                                                ? "Update my results"
                                                : "Submit"
                                        }
                                        disabled={
                                            submitted ? false : disableSubmit
                                        }
                                        className="success"
                                        onClick={handleSubmit}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </>
    );
};

import React, { useState, useContext } from "react";
import { Row, Col, Container, Carousel } from "react-bootstrap";
import Select from "react-select";
import type { ActionMeta, OnChangeValue } from "react-select";
import { useQuery } from "react-query";
import queryString from "query-string";
import get from "lodash/get";
import "../../styles/components/Results.scss";
import { GrPrint } from "react-icons/gr";
import { MdOutlineMailOutline } from "react-icons/md";
import { IconContext } from "react-icons";
import {
    BsFillArrowLeftCircleFill,
    BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { Result } from "./Result";
import { FormatGroup } from "../../types";
import { filter, map, find } from "lodash";
import { Loading } from "../app/common/Loading";
import { ProgressContainer } from "../Survey/ProgressContainer";
import {
    SurveyContext,
    IndexContext,
    IndexDispatchContext,
    AnswersContext,
    Answers,
} from "../Survey/Survey";
import { UnderConstruction } from "../app/common/UnderConstruction";
import {
    infoTypeChoices,
    dataTypeFormula,
    outcomeMatchesFormula,
} from "../../tests/fixtures/surveys";

const directionButtons = (
    direction: string,
    disabled: boolean,
    onClick: () => void
) => {
    const disabledClass = disabled ? "disable-button-action" : "";

    return (
        <span
            aria-hidden="true"
            className={`${
                direction === "Next" ? "button-next" : "button-prev"
            } ${disabledClass}`}
            onClick={onClick}
        >
            {direction === "Next" ? (
                <BsFillArrowRightCircleFill size={35} title="Next Guidance" />
            ) : (
                <BsFillArrowLeftCircleFill
                    size={35}
                    title="Previous Guidance"
                />
            )}
        </span>
    );
};

const translateAnswersToEvidenceTables = (answers?: Answers) => {
    if (!answers) {
        return {
            data_class_code: null,
            data_type_code: null,
            outcome_code: null,
        };
    }

    const { infoType, groups, time, tradeOff, goal } = answers;
    let dataClassCode = null;
    let dataTypeMatch: any = null;
    let outcomeMatch = null;

    // First we need to translate the info type selection to a data_class
    if (infoType && infoType.infoType) {
        const matchedInfoTypeChoice = infoTypeChoices.find(
            (infoTypeChoice) => infoTypeChoice.id === infoType.infoType
        );

        if (matchedInfoTypeChoice) {
            dataClassCode = matchedInfoTypeChoice.foreignReferenceCode;
        }
    }

    // Second we need to get the data type based off the answers of a few different questions
    if (groups && time && tradeOff) {
        dataTypeMatch = dataTypeFormula.find((dtf) => {
            return (
                (Array.isArray(dtf.groupValue)
                    ? dtf.groupValue.includes(groups.groups)
                    : dtf.groupValue === groups.groups) &&
                (Array.isArray(dtf.timeValue)
                    ? dtf.timeValue.includes(time.time)
                    : dtf.timeValue === time.time) &&
                (Array.isArray(dtf.tradeValue)
                    ? dtf.tradeValue.includes(tradeOff.tradeOff)
                    : dtf.tradeValue === tradeOff.tradeOff)
            );
        });
    }

    // Finally we need to determine the outcome based of how the user indicated theirs goals for this app
    if (goal) {
        const goalAnswerKeys = Object.keys(goal);

        const lastGoal = goal[goalAnswerKeys[goalAnswerKeys.length - 1]];

        outcomeMatch = outcomeMatchesFormula.find((om) => {
            return om.datatype
                ? dataTypeMatch &&
                      om.datatype === dataTypeMatch.code &&
                      om.value === lastGoal
                : om.value === lastGoal;
        });
    }

    return {
        data_class_code: dataClassCode,
        data_type_code: dataTypeMatch ? dataTypeMatch.code : null,
        outcome_code: outcomeMatch ? outcomeMatch.code : null,
    };
};

const fetchFormatGroups = async (answers?: Answers) => {
    // User can query guidance directly
    const params = queryString.parse(window.location.search);
    // Otherwise generate guidance based off questionaire
    const answersQuery = translateAnswersToEvidenceTables(answers);

    return fetch(
        `/api/format_groups?data_class_code=${
            params.data_class_code || answersQuery.data_class_code
        }&data_type_code=${
            params.data_type_code || answersQuery.data_type_code
        }&outcome_code=${params.outcome_code || answersQuery.outcome_code}`,
        {
            credentials: "same-origin",
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    )
        .then((res) => res.json())
        .then((res) => {
            return res.map((formatGroup: any) => {
                const { evidence_tables: evidenceTables } = formatGroup;

                let guidanceTiles: any = [];

                evidenceTables.forEach((evidenceTable: any) => {
                    guidanceTiles = [
                        ...guidanceTiles,
                        ...evidenceTable.guidance_tiles,
                    ].sort((a: any, b: any) => {
                        const evidenceStrengthAId = get(
                            a,
                            "evidence_strength.id",
                            0
                        );

                        const evidenceStrengthBId = get(
                            b,
                            "evidence_strength.id",
                            0
                        );

                        return evidenceStrengthAId - evidenceStrengthBId;
                    });
                });

                return {
                    id: formatGroup.id,
                    name: formatGroup.name,
                    code: formatGroup.code,
                    guidanceTiles,
                };
            });
        })
        .catch((error) => {
            console.error("Error:", error);
        });
};

interface Option {
    value: number;
    label: string;
}

interface keyable {
    [key: string]: any;
}

export const Results: React.FC = (): JSX.Element => {
    const survey = useContext(SurveyContext);
    const index = useContext(IndexContext);
    const setIndex = useContext(IndexDispatchContext);
    const answers = useContext(AnswersContext);

    const { data: formatGroups, isLoading } = useQuery<FormatGroup[], Error>(
        "formatGroups",
        () => fetchFormatGroups(answers),
        {
            refetchOnWindowFocus: false,
        }
    );

    const [filterFormatGroup, setFilterFormatGroup] = useState<keyable[]>([]);
    const [slideIndexes, setSlideIndexes] = useState<{ [key: string]: any }>(
        {}
    );

    const onChange = (
        newValue: OnChangeValue<Option, true>,
        actionMeta: ActionMeta<Option>
    ): void => {
        setFilterFormatGroup([...newValue]);
    };

    const handlePrint = () => {
        window.print();
    };

    return isLoading ? (
        <Loading />
    ) : (
        <Container className="results-container">
            <Row>
                <Col sm={12} lg={8} className="pt-4">
                    <h3>Results</h3>
                    {formatGroups && formatGroups.length === 0 ? (
                        <UnderConstruction />
                    ) : (
                        <>
                            <div className="filter-label">
                                Filter guidance by formats
                            </div>
                            <div className="d-flex flex-row align-items-center mb-5">
                                <Select
                                    id="filterSelect"
                                    isMulti
                                    isClearable
                                    placeholder="- All Formats -"
                                    options={[
                                        { value: 0, label: "- All Formats -" },
                                        ...(formatGroups
                                            ? formatGroups.map(
                                                  (formatGroup) => {
                                                      return {
                                                          value: formatGroup.id,
                                                          label: formatGroup.name,
                                                      };
                                                  }
                                              )
                                            : []),
                                    ]}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            minHeight: 52,
                                        }),
                                        dropdownIndicator: (base) => ({
                                            ...base,
                                            color: "#00293B",
                                        }),
                                        indicatorSeparator: (base) => ({
                                            ...base,
                                            display: "none",
                                        }),
                                        placeholder: (base) => ({
                                            ...base,
                                            color: "#00293B",
                                            paddingLeft: "15px",
                                            fontSize: "18px",
                                            fontWeight: 400,
                                            fontFamily: "Open Sans",
                                        }),
                                    }}
                                    onChange={onChange}
                                />
                                <div
                                    className="print-wrapper"
                                    onClick={handlePrint}
                                >
                                    <IconContext.Provider
                                        value={{
                                            size: "32",
                                            className: "icon-teal",
                                        }}
                                    >
                                        <GrPrint />
                                        <span>Print</span>
                                    </IconContext.Provider>
                                </div>
                                {/* <div className="email-wrapper">
                                    <IconContext.Provider
                                        value={{
                                            size: "32",
                                            className: "icon-teal",
                                        }}
                                    >
                                        <MdOutlineMailOutline />
                                        <span>Email</span>
                                    </IconContext.Provider>
                                </div> */}
                            </div>
                        </>
                    )}
                </Col>
                <Col lg={4}>
                    <ProgressContainer
                        title="Edit Selections"
                        survey={survey}
                        index={index}
                        setIndex={setIndex}
                        answers={answers}
                        expandable={true}
                    />
                </Col>
            </Row>
            {formatGroups &&
                formatGroups.length > 0 &&
                map(
                    filter(formatGroups, (formatGroup) => {
                        // Means no selections yet so all which equals true
                        if (filterFormatGroup.length === 0) {
                            return true;
                        }

                        const match = find(filterFormatGroup, (ffg) => {
                            return (
                                ffg.value === 0 || formatGroup.id === ffg.value
                            );
                        });

                        return match ? true : false;
                    }),
                    (filteredFormatGroup) => {
                        const {
                            id: formatGroupId,
                            name,
                            guidanceTiles,
                        } = filteredFormatGroup;

                        const activeIndex = slideIndexes[formatGroupId]
                            ? slideIndexes[formatGroupId]
                            : 0;

                        return (
                            <div key={formatGroupId}>
                                <h4 className="results-title">
                                    {name}{" "}
                                    {`(${activeIndex + 1} of ${
                                        guidanceTiles.length
                                    })`}
                                </h4>
                                <Carousel
                                    fade={false}
                                    interval={null}
                                    controls={true}
                                    indicators={false}
                                    activeIndex={activeIndex}
                                    nextIcon={directionButtons(
                                        "Next",
                                        activeIndex + 1 >= guidanceTiles.length,
                                        () => {
                                            if (
                                                activeIndex + 1 <=
                                                guidanceTiles.length
                                            ) {
                                                setSlideIndexes({
                                                    ...slideIndexes,
                                                    [formatGroupId]:
                                                        activeIndex + 1,
                                                });
                                            }
                                        }
                                    )}
                                    prevIcon={directionButtons(
                                        "Previous",
                                        activeIndex === 0,
                                        () => {
                                            if (activeIndex > 0) {
                                                setSlideIndexes({
                                                    ...slideIndexes,
                                                    [formatGroupId]:
                                                        activeIndex - 1,
                                                });
                                            }
                                        }
                                    )}
                                    className="results-carousel"
                                >
                                    {guidanceTiles.map((guidanceTile) => {
                                        const {
                                            id: guidanceTileId,
                                            evidence_strength: evidenceStrength,
                                            short_guidance: shortGuidance,
                                            example_outcome_lower:
                                                exampleOutcomeLower,
                                            example_outcome_higher:
                                                exampleOutcomeHigher,
                                            comparison,
                                            evidence_table: evidenceTable,
                                        } = guidanceTile;

                                        return (
                                            <Carousel.Item key={guidanceTileId}>
                                                <Result
                                                    key={guidanceTileId}
                                                    summary={shortGuidance}
                                                    type={`strength: ${
                                                        evidenceStrength
                                                            ? evidenceStrength.label ||
                                                              evidenceStrength.name
                                                            : "N/A"
                                                    }`}
                                                    examples={[
                                                        exampleOutcomeLower ||
                                                            "N/A",
                                                        exampleOutcomeHigher ||
                                                            "N/A",
                                                    ]}
                                                    comparison={comparison}
                                                    evidenceTable={
                                                        evidenceTable
                                                    }
                                                />
                                            </Carousel.Item>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        );
                    }
                )}
        </Container>
    );
};

export enum Position {
  LEFT,
  RIGHT,
}

export type EvidenceStrength = {
  id: number,
  name: string,
  label?: string,
  code?: number,
}

export type GuidanceTile = {
  id: number,
  example_outcome_higher?: string,
  example_outcome_lower?: string,
  short_guidance: string,
  comparison?: string,
  evidence_strength: EvidenceStrength,
  evidence_table: any
}

export type EvidenceTable = {
  id: number,
  guidanceTiles: Array<GuidanceTile>
}

export type FormatGroup = {
  id: number,
  name: string,
  code: string,
  evidenceTables: Array<EvidenceTable>,
  guidanceTiles: GuidanceTile[]
}

import React from "react";
import { DefaultTypes } from "../pages/_layouts/types";

export const Footer: React.FC = (): JSX.Element => {
    return (
        <footer className="footer">
            &copy; {new Date().getFullYear()} {DefaultTypes.appTitle}.
            All rights reserved.
        </footer>
    );
};

import { Navbar, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AppRoutes } from '../routes';
import { DefaultTypes } from "../pages/_layouts/types";

import '../../../styles/components/NavHeader.scss';

export const NavHeader: React.FC = (): JSX.Element => {
    return (
        <Navbar className="justify-content-center navbar-dark" collapseOnSelect expand={false}>
            <Container>
              <NavDropdown className="navbar-dropdown" title={<><span className="navbar-toggler-icon"></span><span>Menu</span></>} id="nav-dropdown">
                <NavDropdown.Item as={Link} to={`/${AppRoutes.About}`} eventKey="about">About</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={`/${AppRoutes.Resources}`} eventKey="resources">Resources</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={`/${AppRoutes.Research}`} eventKey="research">Research</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={`/${AppRoutes.Team}`} eventKey="team">Team</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to={`/${AppRoutes.Tutorial}`} eventKey="tutorial">Tutorial</NavDropdown.Item>
              </NavDropdown>
              <Navbar.Brand className="flex-grow-1 text-center"><Link to="/">{DefaultTypes.appTitle}</Link></Navbar.Brand>
            </Container>
        </Navbar>
    );
};

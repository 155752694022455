import { useState, useEffect, useRef } from "react";
import { useQueryClient, useQuery } from "react-query";
import find from "lodash/find";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { Form, Row, Col } from "react-bootstrap";

import { ISurvey, Question, Choice, ChoiceCondition, Answers } from "./Survey";
import Button from "../app/common/Button";
import { DisclaimerModal } from "../app/common/DisclaimerModal";
import { Position } from "../../types";

interface ISectionQuestion {
    survey: ISurvey;
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    answers: any;
    handleSetAnswer: (
        pageName: string,
        question: Question,
        value: number,
        answersToReset: string[]
    ) => void;
}

const getQuestionChoices = (question: Question, answers: Answers) => {
    let choices: Choice[] = [];

    if (question.choiceConditions) {
        // Another question determines what this question has as options so need to find the match
        const matchedConditions = find(
            question.choiceConditions,
            (choiceCondition: ChoiceCondition) => {
                const { choiceId } = choiceCondition;
                let match = false;

                for (const page in answers) {
                    const pageAnswers = answers[page];

                    for (const answerKey in pageAnswers) {
                        if (pageAnswers[answerKey] === choiceId) {
                            match = true;
                            break;
                        }
                    }
                }

                return match;
            }
        );

        if (matchedConditions) {
            choices = matchedConditions.choices;
        }
    } else {
        // Is simple just use the options/choices the question has as default
        choices = question.choices ? question.choices : [];
    }

    return choices;
};

export const SectionQuestions: React.FC<ISectionQuestion> = ({
    survey,
    index,
    setIndex,
    answers,
    handleSetAnswer,
}): JSX.Element => {
    const queryClient = useQueryClient();
    const defaultQueryParams = {
        refetchOnWindowFocus: false,
        enabled: false,
    };
    const { data: activeSubQuestionsCache } = useQuery<Question[], Error>(
        "activeSubQuestions",
        defaultQueryParams
    );
    const { data: sectionQuestionInfoTextCache } = useQuery<
        {
            [key: number]: string;
        },
        Error
    >("sectionQuestionInfoText", defaultQueryParams);

    const [optionValue, setOptionValue] = useState<any>({});
    const { pages } = survey;
    const currentPage = pages[index];
    const [activeSubQuestions, setActiveSubQuestions] = useState<Question[]>(
        activeSubQuestionsCache && answers ? activeSubQuestionsCache : []
    );
    const [triggerDisclaimer, setTriggerDisclaimer] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
    const [sectionQuestionInfoText, setSectionQuestionInfoText] = useState<{
        [key: number]: string;
    }>(
        sectionQuestionInfoTextCache && answers
            ? sectionQuestionInfoTextCache
            : {}
    );
    const prevIndex = useRef(-1);

    const handleOptionChange = (choice: Choice, question: Question) => {
        const prevAnswers =
            optionValue && optionValue[question.name]
                ? { ...optionValue[question.name] }
                : {};

        setOptionValue({
            ...prevAnswers,
            [question.name]: choice.id,
        });

        const answersToReset: string[] = [];

        // Start to handle potential branching sub questions that have specific conditions
        if (
            choice.subQuestionId &&
            activeSubQuestions &&
            activeSubQuestions.length >= 0
        ) {
            const subjectQuestionExists = activeSubQuestions.find((sq) => {
                return sq.id === choice.subQuestionId;
            });

            if (!subjectQuestionExists && currentPage.subQuestions) {
                const subQuestion = currentPage.subQuestions.find(
                    (subQuestion) => choice.subQuestionId === subQuestion.id
                );

                if (subQuestion) {
                    const newActiveSubQuestions = [
                        ...activeSubQuestions,
                        subQuestion,
                    ];

                    setActiveSubQuestions(newActiveSubQuestions);
                    queryClient.setQueryData(
                        "activeSubQuestions",
                        newActiveSubQuestions
                    );

                    if (sectionQuestionInfoText) {
                        setSectionQuestionInfoText({});
                    }
                }
            }
        } else if (
            activeSubQuestions &&
            activeSubQuestions.length > 0 &&
            currentPage &&
            currentPage.pageName &&
            currentPage.subQuestions
        ) {
            // Possible responses could of changed so may have to hide branching sub questions
            const currentPageAnswers = {
                ...answers[currentPage.pageName],
                [question.name]: choice.id,
            };

            const newActiveSubQuestions = activeSubQuestions.filter((sq) => {
                const { id, name: questionName } = sq;

                const showSubQuestion =
                    currentPage.questions && currentPage.questions.length > 0
                        ? [
                              ...currentPage.questions,
                              ...activeSubQuestions,
                          ].find((q) => {
                              let parent = null;
                              const choices = q.choices ? q.choices : [];

                              for (const answer in currentPageAnswers) {
                                  if (!parent) {
                                      parent = choices.find(
                                          (choice: Choice) => {
                                              return (
                                                  choice.id ===
                                                  currentPageAnswers[answer]
                                              );
                                          }
                                      );
                                  }
                              }

                              if (
                                  parent &&
                                  parent.subQuestionId &&
                                  parent.subQuestionId === id
                              ) {
                                  return true;
                              }

                              return false;
                          })
                        : false;

                if (!showSubQuestion) {
                    // If sub question is invalid we need to clear out the previous answer
                    answersToReset.push(questionName);
                }

                return showSubQuestion;
            });

            setActiveSubQuestions(newActiveSubQuestions);
            queryClient.setQueryData(
                "activeSubQuestions",
                newActiveSubQuestions
            );
        }

        if (currentPage.pageName) {
            handleSetAnswer(
                currentPage.pageName,
                question,
                choice.id,
                answersToReset
            );
        }

        if (choice.infoText) {
            const newSectionQuestionInfoText = {
                [question.id]: choice.infoText,
            };

            setSectionQuestionInfoText(newSectionQuestionInfoText);

            // Set the query cache incase we navigate back here from results
            queryClient.setQueryData(
                "sectionQuestionInfoText",
                newSectionQuestionInfoText
            );
        }

        // Only one scenario but some choices will trigger a disclaimer modal message
        if (choice.showDisclaimer) {
            setSectionQuestionInfoText({});
            setTriggerDisclaimer(true);
        } else {
            setTriggerDisclaimer(false);
        }
    };

    useEffect(() => {
        if (prevIndex.current !== index) {
            if (currentPage && currentPage.pageName) {
                setOptionValue(
                    answers && answers[currentPage.pageName]
                        ? answers[currentPage.pageName]
                        : {}
                );
            }
        }

        prevIndex.current = index;
    }, [index, currentPage, answers]);

    if (
        currentPage &&
        currentPage.questions &&
        currentPage.questions.length > 0
    ) {
        const subQuestions = currentPage.subQuestions
            ? currentPage.subQuestions
            : [];
        let disableNext = true;

        if (
            answers &&
            currentPage.pageName &&
            answers[currentPage.pageName] &&
            currentPage.questions &&
            Object.keys(answers[currentPage.pageName]).length ===
                currentPage.questions.length +
                    (currentPage.subQuestions ? activeSubQuestions.length : 0)
        ) {
            disableNext = false;
        }

        return (
            <>
                <div className="question-container">
                    {[
                        ...currentPage.questions,
                        ...subQuestions.filter((subQuestion) => {
                            return activeSubQuestions.find(
                                (activeSubQuestion) =>
                                    activeSubQuestion.id === subQuestion.id
                            );
                        }),
                    ].map((question, key) => {
                        const choices = getQuestionChoices(question, answers);

                        return (
                            <div className="question-section" key={key}>
                                {key > 0 && (
                                    <div className="divider col-sm-10">
                                        <br />
                                    </div>
                                )}
                                <h4 className="question-title">
                                    {question.title}
                                </h4>
                                {question.description && (
                                    <div className="question-description">
                                        {question.description}
                                    </div>
                                )}
                                <Form className="questions-form">
                                    <Form.Group
                                        as={Row}
                                        className="mb-3 form-group"
                                    >
                                        {choices.map((choice, key) => {
                                            const isActiveChoice =
                                                answers &&
                                                currentPage.pageName &&
                                                answers[currentPage.pageName] &&
                                                answers[currentPage.pageName][
                                                    question.name
                                                ] &&
                                                answers[currentPage.pageName][
                                                    question.name
                                                ] === choice.id;

                                            return (
                                                <Col
                                                    sm={10}
                                                    key={`${currentPage.pageName}_${key}`}
                                                >
                                                    <Button
                                                        title={`${
                                                            choice.label
                                                        } ${
                                                            isActiveChoice &&
                                                            choice.warningLabel
                                                                ? `Warning: ${choice.warningLabel}`
                                                                : ""
                                                        }`}
                                                        description={
                                                            choice.description
                                                        }
                                                        onClick={() =>
                                                            handleOptionChange(
                                                                choice,
                                                                question
                                                            )
                                                        }
                                                        active={isActiveChoice}
                                                    ></Button>
                                                </Col>
                                            );
                                        })}
                                    </Form.Group>
                                </Form>
                                {sectionQuestionInfoText &&
                                    sectionQuestionInfoText[question.id] && (
                                        <>
                                            <div className="divider col-sm-10"></div>
                                            <br />
                                            <div className="question-info-text-container">
                                                <span className="question-title question-info-text">
                                                    {
                                                        sectionQuestionInfoText[
                                                            question.id
                                                        ]
                                                    }
                                                </span>
                                            </div>
                                        </>
                                    )}
                                {currentPage.disclaimerText && (
                                    <>
                                        <div className="divider col-sm-10"></div>
                                        <br />
                                        <span className="question-title question-info-text">
                                            *{currentPage.disclaimerText}
                                        </span>
                                        <br />
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="divider col-sm-10"></div>
                <div className="d-flex justify-content-evenly col-sm-10">
                    <Button
                        title="Previous"
                        onClick={() => {
                            if (index !== 0) {
                                setIndex(index - 1);
                            }
                        }}
                        icon={<GrPrevious />}
                        iconPosition={Position.LEFT}
                        disabled={index === 0 ? true : false}
                    ></Button>
                    <Button
                        title="Next"
                        disabled={disableNext}
                        onClick={() => {
                            if (triggerDisclaimer) {
                                setShowDisclaimerModal(true);
                            } else {
                                setIndex(index + 1);
                            }
                        }}
                        icon={<GrNext />}
                        iconPosition={Position.RIGHT}
                    ></Button>
                </div>
                <DisclaimerModal
                    show={showDisclaimerModal}
                    onClose={() => setShowDisclaimerModal(false)}
                />
            </>
        );
    }

    return <div style={{ color: "red" }}>Under Construction</div>;
};

import React from "react";
import { Route } from "react-router-dom";

import { DefaultLayout } from "../pages/_layouts/DefaultLayout";

interface RouteWrapper {
    // Ensure were passed in a component
    component: React.ComponentType<any>;
    // Also make clear we can take props of any given type
    [key: string]: any;
}


const CustomRoute: React.FC<RouteWrapper> = ({
    component: Component,
    ...rest
}): JSX.Element => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <DefaultLayout>
                    <Component {...props} />
                </DefaultLayout>
            )}
        />
    );
};

export default CustomRoute;
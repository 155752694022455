import Spinner from "react-bootstrap/Spinner";

import '../../../styles/components/Loading.scss';

export const Loading = () => {
    return (
        <div className="spinner-container">
            <Spinner
                animation="border"
                variant="primary"
                className="spinner"
            />
        </div>
    );
};

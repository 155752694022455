import React from "react";
import parse from 'html-react-parser'
import { Button as PrimaryButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Position } from '../../../types';

interface Props {
  title: string;
  description?: string;
  onClick?: () => void;
  pathName?: any;
  width?: string;
  icon?: React.ReactNode;
  iconPosition?: Position;
  disabled?: boolean;
  active?: boolean;
  className?: string;
}

const Button: React.FC<Props> = ({
  title,
  description,
  onClick,
  pathName,
  width,
  icon,
  iconPosition,
  disabled,
  active,
  className,
}) => {
  let activeClass: string = active ? 'active' : '';
  let disabledClass: string = disabled ? 'disabled' : '';
  if (pathName) {
    return (
      <Link className="btn-link" to={pathName}>
        <div className="d-grid">
          <PrimaryButton
              variant="primary"
              size="lg"
              className={`${activeClass} ${disabledClass} ${className}`}
          >
              {title}
              {description? <p className="btn-description">{description}</p> : null}
          </PrimaryButton>
         </div>
      </Link>
    );
  } else if (iconPosition === Position.LEFT) {
      return (
        <div className="d-grid">
          <PrimaryButton
              variant="primary"
              size="lg"
              onClick={onClick}
              className={`${activeClass} ${disabledClass} ${className}`}
          >
              {icon? <>{icon}</> : null}
              {title}
              {description? <p className="btn-description">{description}</p> : null}
          </PrimaryButton>
         </div>
      );
  } else {
    return (
      <div className="d-grid">
        <PrimaryButton
            variant="primary"
            size="lg"
            onClick={onClick}
            className={`${activeClass} ${disabledClass} ${className}`}
        >
            {parse(title)}
            {description? <p className="btn-description">{description}</p> : null}
            {icon? <>{icon}</> : null}
        </PrimaryButton>
       </div>
    );
  }
}

export default Button;

// Note we're not storing user responses so atm simpler to have survey be a json/static
// In the future if this changes can def migrate this to a database
export const infoTypeChoices = [
    {
        id: 11,
        label: "Probabilities",
        description: "(e.g. risk, benefit)",
        shortLabel: "Probabilities",
        foreignReferenceCode: "probability",
    },
    {
        id: 12,
        label: "Quantities",
        description:
            "(e.g. lab value, patient reported outcome, environmental hazard)",
        shortLabel: "Quantities",
        foreignReferenceCode: "quantity",
    },
];

export const survey = {
    pages: [
        {
            id: 1,
            navigationTitle: "Goal",
            pageName: "goal",
            disclaimerText:
                "We recognize that you might have multiple goals for your communication, or that your goals may not be in this list, but unfortunately in the current version of our guidance, we have to ask you to pick one of the above goals at a time.",
            questions: [
                {
                    id: 1,
                    type: "radiogroup",
                    //description: "I want to:",
                    name: "broadGoal",
                    isRequired: true,
                    title: "What do you most want people to think, recognize, feel, or do about the number(s)?",
                    colCount: 4,
                    choices: [
                        {
                            id: 1,
                            label: "They should see the number(s) or the difference(s) between the number(s) as <b className='option-bold'>large</b> or <b className='option-bold'>more concerning</b>.",
                            shortLabel:
                                "See number(s) as larger, more concerning",
                        },
                        {
                            id: 2,
                            label: "They should see the number(s) or the difference(s) between the number(s) as <b className='option-bold'>small</b> or <b className='option-bold'>less concerning</b>.",
                            shortLabel:
                                "See number(s) as smaller, less concerning",
                        },
                        {
                            id: 3,
                            label: "They should recognize that the number(s) or the difference(s) between the number(s) fall into a particular <b className='option-bold'>category</b>.",
                            shortLabel: "Classify number(s) into a framework",
                            description:
                                "(e.g., high | borderline | normal | low, or above a threshold vs below a threshold)",
                        },
                        {
                            id: 4,
                            label: "They should be able to <b className='option-bold'>compare</b> numbers to identify which is largest or smallest.",
                            shortLabel:
                                "Select largest/smallest number, best/worst option",
                        },
                        {
                            id: 5,
                            label: "They should take <b className='option-bold'>action</b>, regardless of their feelings or thoughts.",
                            warningLabel:
                                "Numbers may not be the best way to promote desired health behaviors directly; when they do influence future behaviors, it’s typically through thoughts or feelings about the numbers",
                            shortLabel: "Encourage behavior/action",
                            description:
                                "(e.g. they should get tested, adopt a healthy behavior, or avoid an unhealthy one)",
                        },
                        {
                            id: 6,
                            label: "They should be able to identify particular numbers in a communication or differences between particular numbers, regardless of their feelings about them.",
                            shortLabel: "Identify particular number(s)",
                        },
                        // {
                        //     id: 1,
                        //     label: "Yes, everyone receiving the message should do the same thing.",
                        //     description: "(For example, everyone should get tested, avoid something, etc.)",
                        //     shortLabel: "Encourage behavior/action",
                        //     infoText: "Based on your answers, we will show you recommendations about your goal of encouraging certain behaviors or actions. Now we need to ask more questions about the number(s) you are communicating."
                        // },
                        // {
                        //     id: 2,
                        //     label: "Yes, everyone should do something, but what they should do is different from one person to another.",
                        //     description: "(For example, only people at high risk should take some protective action, but people at low risk should not.)",
                        //     subQuestionId: 2,
                        // },
                        // {
                        //     id: 3,
                        //     label: "No, people may not need to do anything.",
                        //     subQuestionId: 2,
                        // },
                    ],
                },
            ],
            // subQuestions: [
            //     {
            //         id: 2,
            //         type: "radiogroup",
            //         name: "broadGoal2",
            //         isRequired: true,
            //         title: "Is there something that you want people receiving the message to believe or feel about the number?",
            //         colCount: 4,
            //         choices: [
            //             {
            //                 id: 4,
            //                 label: "Yes, they should see it as a larger risk, a higher value, a more concerning result, bigger difference, etc.",
            //                 shortLabel:
            //                     "See number(s) as large, value, more concerning",
            //                 infoText: "Based on your answers, we will show you recommendations about your goal of helping people see the numbers(s) as larger, higher, more concerning, etc. Now we need to ask more questions about the number(s) you are communicating."
            //             },
            //             {
            //                 id: 5,
            //                 label: "Yes, they should see it as a smaller risk, a lower value, a less concerning result, smaller difference, etc.",
            //                 shortLabel:
            //                     "See number(s) as smaller, lower, less concerning",
            //                 infoText: "Based on your answers, we will show you recommendations about your goal of helping people see the number(s) as smaller, lower, less concerning, etc. Now we need to ask more questions about the number(s) you are communicating."
            //             },
            //             {
            //                 id: 6,
            //                 label: "Yes, they should recognize it falls into a particular category",
            //                 description: "(e.g., very high/borderline/normal, common/rare)",
            //                 shortLabel: "Classify number(s) into a framework",
            //                 infoText: "Based on your answers, we will show you evidence about your goal of helping readers classify a value into a framework (e.g. high, normal, low). Now we need to ask more questions about the number(s) you are communicating."
            //             },
            //             {
            //                 id: 7,
            //                 label: "No, people should form their own beliefs about the information.",
            //                 subQuestionId: 3,
            //             },
            //         ],
            //     },
            //     {
            //         id: 3,
            //         type: "radiogroup",
            //         name: "broadGoal3",
            //         isRequired: true,
            //         title: "Based on your previous answers, there’s no health-related action that the person(s) you are communicating with should take or belief you want to encourage in response to the number(s) you are communicating. Do people need to compare or remember the number(s)? ",
            //         colCount: 4,
            //         choices: [
            //             {
            //                 id: 8,
            //                 label: "Yes, people to remember the number(s)",
            //                 shortLabel: "Recall a number(s)",
            //                 infoText:
            //                     "Based on your answers, we will show you evidence about your goal of improving recall of the number(s) you are communicating. Now we need to ask more questions about the number(s) you are communicating",
            //             },
            //             {
            //                 id: 9,
            //                 label: "Yes, people need to be able to compare numbers.",
            //                 description: "(For example, finding the larger or smaller number)",
            //                 shortLabel:
            //                     "Select largest/smallest number, best/worst option",
            //                 infoText:
            //                     "Based on your answers, we will show you evidence about your goal of helping people compare the number(s) you are communicating. Now we need to ask more questions about the number(s) you are communicating.",
            //             },
            //             {
            //                 id: 10,
            //                 label: "None of the above",
            //                 showDisclaimer: true,
            //                 shortLabel: "None of the above"
            //             },
            //         ],
            //     },
            // ],
        },
        {
            id: 2,
            navigationTitle: "Info Type",
            pageName: "infoType",
            questions: [
                {
                    id: 4,
                    type: "radiogroup",
                    name: "infoType",
                    isRequired: true,
                    title: "Tell us more about your data",
                    description:
                        "Are you communicating about probabilities (e.g., risks) or quantities (e.g., lab test results)?",
                    colCount: 4,
                    choices: infoTypeChoices,
                },
            ],
        },
        {
            id: 3,
            navigationTitle: "Groups",
            pageName: "groups",
            questions: [
                {
                    id: 5,
                    type: "radiogroup",
                    name: "groups",
                    isRequired: true,
                    title: "Do you want to communicate?",
                    colCount: 4,
                    choiceConditions: [
                        {
                            sectionId: 2,
                            choiceId: 11,
                            choices: [
                                {
                                    id: 13,
                                    label: "One or a few numbers",
                                    description:
                                        "(e.g., the lifetime risk of breast cancer; the rates of side effects of a drug)",
                                    shortLabel: "One person or population",
                                },
                                {
                                    id: 14,
                                    label: "A larger set of numbers that people will use to find one number that matters to them",
                                    description:
                                        "(e.g., a lookup table of different cancer risks based on age)",
                                    shortLabel: "A larger set of numbers",
                                },
                                {
                                    id: 15,
                                    label: "A pair of numbers expressing the effect of a factor, intervention, or therapy",
                                    description:
                                        "(e.g., the risk decreased from 10% to 7%)",
                                    shortLabel: "A pair of numbers",
                                },
                                {
                                    id: 16,
                                    label: "A number that expresses the difference between two other numbers",
                                    description:
                                        "(e.g., the risk of cancer increases by 3 people per 100 with exposure to some carcinogen; the treatment reduces the death rate by 33%)",
                                    shortLabel:
                                        "A number that expresses difference between two numbers",
                                },
                            ],
                        },
                        {
                            sectionId: 2,
                            choiceId: 12,
                            choices: [
                                {
                                    id: 17,
                                    label: "One or a few numbers",
                                    description:
                                        "(e.g., lab values, the levels of different contaminants in their water)",
                                    shortLabel: "One person or population",
                                },
                                {
                                    id: 18,
                                    label: "A larger set of numbers that people will use to find one number that matters to them",
                                    description:
                                        "(e.g., a lookup table of normal lab values based on gender, age)",
                                    shortLabel: "A larger set of numbers",
                                },
                                {
                                    id: 19,
                                    label: "A pair of numbers expressing the effect of a factor, intervention, or therapy",
                                    description:
                                        "(e.g., the lab value, for example average HbA1c decreased from 7.1% to 6.4% based on improved diet and exercise)",
                                    shortLabel: "A pair of numbers",
                                },
                                {
                                    id: 20,
                                    label: "A number that expresses the difference between two other numbers",
                                    description:
                                        "(e.g.,  residents of affected communities had blood lead levels on average 5 µg/dL higher than the statewide average; the treatment reduces cholesterol levels by 33%)",
                                    shortLabel:
                                        "A number that expresses difference between two numbers",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: 4,
            navigationTitle: "Time",
            pageName: "time",
            questions: [
                {
                    id: 6,
                    type: "radiogroup",
                    name: "time",
                    isRequired: true,
                    title: "How many time points are you communicating about?",
                    colCount: 4,
                    choiceConditions: [
                        {
                            sectionId: 2,
                            choiceId: 11,
                            choices: [
                                {
                                    id: 21,
                                    label: "Data at one time",
                                    description:
                                        "(e.g., risk of re-admission based on patient’s data)",
                                    shortLabel: "Data at one time",
                                },
                                {
                                    id: 22,
                                    label: "Data over one time period",
                                    description:
                                        "(e.g., a patient’s 10-year risk of a disease, a lifetime risk of a disease)",
                                    shortLabel: "Data over one time period",
                                },
                                {
                                    id: 23,
                                    label: "Data at exactly 2 times to show a comparison",
                                    description:
                                        "(e.g., pre-treatment vs. post-treatment, treated patients vs. untreated patients)",
                                    shortLabel: "Data at exactly 2 times",
                                },
                                {
                                    id: 24,
                                    label: "Data at 3 or more times that shows time trends",
                                    shortLabel: "Data at 3 or more times",
                                },
                            ],
                        },
                        {
                            sectionId: 2,
                            choiceId: 12,
                            choices: [
                                {
                                    id: 25,
                                    label: "Data at one time",
                                    description: "(e.g., single lab value)",
                                    shortLabel: "Data at one time",
                                },
                                {
                                    id: 26,
                                    label: "Data over one time period",
                                    description:
                                        " (e.g., a patient’s average fasting HbA1c over the course of a month; annual average air contamination levels)",
                                    shortLabel: "Data over one time period",
                                },
                                {
                                    id: 27,
                                    label: "Data at exactly 2 times to show a comparison",
                                    description:
                                        "(e.g., pre-treatment vs. post-treatment, treated patients vs. untreated patients)",
                                    shortLabel: "Data at exactly 2 times",
                                },
                                {
                                    id: 28,
                                    label: "Data at 3 or more times that shows time trends",
                                    shortLabel: "Data at 3 or more times",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: 5,
            navigationTitle: "Trade-Off",
            pageName: "tradeOff",
            questions: [
                {
                    id: 7,
                    type: "radiogroup",
                    name: "tradeOff",
                    isRequired: true,
                    title: " Is this a trade-off (e.g., a communication that includes BOTH data about benefits and data about risks or harms)?",
                    colCount: 4,
                    choices: [
                        {
                            id: 29,
                            label: "Yes",
                            shortLabel: "Yes",
                        },
                        {
                            id: 30,
                            label: "No",
                            shortLabel: "No",
                        },
                    ],
                },
            ],
        },
        {
            navigationTitle: "Results",
            pageName: "results",
        },
    ],
};

export const outcomeMatchesFormula = [
    {
        questionName: "broadGoal",
        value: 1,
        datatype: 0,
        code: "PerceivedProbability",
    },
    {
        questionName: "broadGoal",
        value: 2,
        datatype: 0,
        code: "PerceivedProbability",
    },
    {
        questionName: "broadGoal",
        value: 1,
        datatype: 1,
        code: "SubjectiveEffectiveness",
    },
    {
        questionName: "broadGoal",
        value: 2,
        datatype: 1,
        code: "SubjectiveEffectiveness",
    },
    {
        questionName: "broadGoal",
        value: 3,
        code: "Normative Framework",
    },
    {
        questionName: "broadGoal",
        value: 4,
        code: "NormativeDominant",
    },
    {
        questionName: "broadGoal",
        value: 5,
        code: "BehavioralIntentionOrBehavior",
    },
    {
        questionName: "broadGoal",
        value: 6,
        code: "Recall",
    },
];

const groupA = [13, 17];
const groupB = [14, 18];
const groupC = [15, 19];
const groupD = [16, 20];

const timeA = [21, 25];
const timeB = [22, 26];
const timeC = [23, 27];
const timeD = [24, 28];

const tradeA = 29;
const tradeB = 30;

const type0Code = 0;
const type1Code = 1;
const type2Code = 2;
const type3Code = 3;

export const dataTypeFormula = [
    {
        groupValue: groupA,
        timeValue: timeA,
        tradeValue: tradeB,
        code: type0Code,
    },
    {
        groupValue: groupA,
        timeValue: timeA,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupA,
        timeValue: timeB,
        tradeValue: tradeB,
        code: type0Code,
    },
    {
        groupValue: groupA,
        timeValue: timeB,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupA,
        timeValue: timeC,
        tradeValue: tradeB,
        code: type1Code,
    },
    {
        groupValue: groupA,
        timeValue: timeC,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupA,
        timeValue: timeD,
        tradeValue: tradeB,
        code: type3Code,
    },
    {
        groupValue: groupA,
        timeValue: timeD,
        tradeValue: tradeA,
        code: type3Code,
    },
    {
        groupValue: groupB,
        timeValue: timeA,
        tradeValue: tradeB,
        code: type0Code,
    },
    {
        groupValue: groupB,
        timeValue: timeA,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupB,
        timeValue: timeB,
        tradeValue: tradeB,
        code: type0Code,
    },
    {
        groupValue: groupB,
        timeValue: timeB,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupB,
        timeValue: timeC,
        tradeValue: tradeB,
        code: type1Code,
    },
    {
        groupValue: groupB,
        timeValue: timeC,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupB,
        timeValue: timeD,
        tradeValue: tradeB,
        code: type3Code,
    },
    {
        groupValue: groupB,
        timeValue: timeD,
        tradeValue: tradeA,
        code: type3Code,
    },
    {
        groupValue: groupC,
        timeValue: timeA,
        tradeValue: tradeB,
        code: type1Code,
    },
    {
        groupValue: groupC,
        timeValue: timeA,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupC,
        timeValue: timeB,
        tradeValue: tradeB,
        code: type1Code,
    },
    {
        groupValue: groupC,
        timeValue: timeB,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupC,
        timeValue: timeC,
        tradeValue: tradeB,
        code: type1Code,
    },
    {
        groupValue: groupC,
        timeValue: timeC,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupC,
        timeValue: timeD,
        tradeValue: tradeB,
        code: type3Code,
    },
    {
        groupValue: groupC,
        timeValue: timeD,
        tradeValue: tradeA,
        code: type3Code,
    },
    {
        groupValue: groupD,
        timeValue: timeA,
        tradeValue: tradeB,
        code: type1Code,
    },
    {
        groupValue: groupD,
        timeValue: timeA,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupD,
        timeValue: timeB,
        tradeValue: tradeB,
        code: type1Code,
    },
    {
        groupValue: groupD,
        timeValue: timeB,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupD,
        timeValue: timeC,
        tradeValue: tradeB,
        code: type1Code,
    },
    {
        groupValue: groupD,
        timeValue: timeC,
        tradeValue: tradeA,
        code: type2Code,
    },
    {
        groupValue: groupD,
        timeValue: timeD,
        tradeValue: tradeB,
        code: type3Code,
    },
    {
        groupValue: groupD,
        timeValue: timeD,
        tradeValue: tradeA,
        code: type3Code,
    },
];
